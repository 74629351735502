import React from "react";
import { blogs } from "../materials/blog";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import TextHeader from "../components/TextHeader";

const ViewBlogPage = ({ language }) => {
  const { id } = useParams();
  const isUkrainian = language === "uk";

  const item = blogs[id - 1];

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <TextHeader text={isUkrainian ? item.name_ukr : item.name_en} />
      <Container
        maxWidth={"lg"}
        sx={{
          position: "relative",
          margin: "0px 0px 50px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant={"h6"}
          sx={{ textAlign: "center", margin: "20px 0px" }}
        >
          {isUkrainian ? item.description_uk : item.description_en}
        </Typography>
        <Box
          component={"img"}
          src={item.image}
          alt={"logo"}
          sx={{ width: { xs: "100%", md: "45%" } }}
        />
      </Container>
    </Box>
  );
};

export default ViewBlogPage;
