import React, { useRef } from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import logistic from "../../assets/logistic.jpg";

const Logistic = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader text={isUkrainian ? "Логістика" : "Logistic"} />
      <Typography
        variant={"h6"}
        sx={{ margin: "10px 0px 50px 0px", textAlign: "center" }}
      >
        {isUkrainian
          ? "Ми - ваш надійний партнер у сфері логістики. Наша компанія пропонує комплексні та індивідуальні рішення для оптимізації транспортних та логістичних процесів вашого бізнесу. Ми розуміємо, наскільки важлива є ефективна логістика для успішної роботи підприємств, тому пропонуємо широкий спектр послуг з урахуванням індивідуальних потреб кожного клієнта. Ми прагнемо стати вашим надійним партнером і сприяти вашому бізнесу у досягненні успіху. Звертайтесь до нас для отримання консультацій та розробки оптимального логістичного рішення. Ми готові взяти на себе зв'язані з логістикою завдання, щоб ви могли зосередитися на розвитку свого бізнесу!"
          : "We are your reliable partner in the field of logistics. Our company offers complex and individual solutions to optimize the transport and logistics processes of your business. We understand how important effective logistics is for the successful operation of enterprises, therefore we offer a wide range of services taking into account the individual needs of each client. We strive to become your reliable partner and help your business succeed. Contact us for consultation and development of the optimal logistics solution. We are ready to take on the tasks related to logistics, so that you can focus on the development of your business!"}
      </Typography>
      <Box
        component={"img"}
        src={logistic}
        alt={"logistic"}
        sx={{ maxWidth: "100%" }}
      />
    </Container>
  );
};

export default Logistic;
