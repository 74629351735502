import React, { useRef } from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import cargo from "../../assets/railway.jpg";

const ZD = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader text={isUkrainian ? "Залізниця" : "Railway"} />
      <Typography
        variant={"h6"}
        sx={{ margin: "10px 0px 50px 0px", textAlign: "center" }}
      >
        {isUkrainian
          ? "Наша компанія - ваш надійний партнер у залізничних перевезеннях. Ми пропонуємо широкий спектр послуг, пов'язаних з залізничним транспортом, що дозволяє нам забезпечити ефективну, швидку та безпечну доставку вашого вантажу. Ми готові забезпечити вас найкращими рішеннями для вашої залізничної логістики. Звертайтесь до нас замовленнями та питаннями - ми завжди раді допомогти вам реалізувати ваші проекти з найвищою якістю та ефективністю. Нехай ваш вантаж подорожує з нами безпечно та успішно! "
          : "Our company is your reliable partner in railway transportation. We offer a wide range of services related to rail transport, which allows us to ensure efficient, fast and safe delivery of your cargo. We are ready to provide you with the best solutions for your rail logistics. Contact us with orders and questions - we are always happy to help you implement your projects with the highest quality and efficiency. Let your cargo travel safely and successfully with us!"}
      </Typography>
      <Box
        component={"img"}
        src={cargo}
        alt={"cargo"}
        sx={{ maxWidth: "100%" }}
      />
    </Container>
  );
};

export default ZD;
