import React, { useRef } from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import delivery from "../../assets/lastBackLight.jpg";

const Port = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader text={isUkrainian ? "Доставка" : "Delivery"} />
      <Typography
        variant={"h6"}
        sx={{ margin: "10px 0px 50px 0px", textAlign: "center" }}
      >
        {isUkrainian
          ? "Наша компанія пропонує надійні та ефективні послуги доставки для вашого зручного та безперебійного досвіду. Ми розуміємо, наскільки важлива є своєчасна та професійна доставка, тому гарантуємо, що ваші товари та вантажі будуть доставлені вчасно та безпечно. Судноплавною компанією ANK за 2021 рік було перевезено 𝟑𝟖𝟓 𝟎𝟎𝟏 тонн вантажу, що становить 𝟐,𝟔𝟖% від загального вантажообігу України."
          : "Our company offers reliable and efficient delivery services for your convenient and hassle-free experience. We understand how important timely and professional delivery is, so we guarantee that your goods and cargo will be delivered on time and safely. ANK shipping company transported 𝟑𝟖𝟓 𝟎𝟎𝟏 tons of cargo in 2021, which is 𝟐.𝟔𝟖% of the total cargo turnover of Ukraine."}
      </Typography>
      <Box
        component={"img"}
        src={delivery}
        alt={"logistic"}
        sx={{ maxWidth: "100%" }}
      />
    </Container>
  );
};

export default Port;
