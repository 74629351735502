import React from "react";
import { Container, Typography } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import cement from "../../assets/beton.jpg";

const Beton = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader text={isUkrainian ? "Бетон" : "Concrete"} />
      <Typography
        variant={"h6"}
        sx={{ margin: "10px 0px 50px 0px", textAlign: "center" }}
      >
        {isUkrainian
          ? "Наша компанія з радістю представляє вам високоякісні бетонні рішення, що задовольнять будь-які ваші будівельні потреби. Ми маємо багаторічний досвід у виробництві та постачанні бетону для різноманітних проектів - від житлових будівель і комерційних комплексів до інфраструктурних об'єктів і дорожнього будівництва. Нехай наш бетон стане надійним фундаментом вашого успішного будівництва! Звертайтесь до нас замовленнями та питаннями - ми завжди раді допомогти вам реалізувати ваші проекти на найвищому рівні якості."
          : "Our company is pleased to present you with high-quality concrete solutions that will satisfy any of your construction needs. We have many years of experience in the production and supply of concrete for a variety of projects - from residential buildings and commercial complexes to infrastructure facilities and road construction. Let our concrete become a reliable foundation for your successful construction! Contact us with orders and questions - we are always happy to help you implement your projects at the highest level of quality."}
      </Typography>
      <img src={cement} alt={"cement"} style={{ maxWidth: "100vw" }} />
    </Container>
  );
};

export default Beton;
