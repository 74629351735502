import React from "react";
import { Typography } from "@mui/material";

const TextHeader = ({ text }) => {
  return (
    <Typography
      align={"center"}
      variant={"h4"}
      sx={{
        fontWeight: "600",
        margin: "50px 0px 50px 0px",
        position: "relative",
        paddingBottom: "2rem",
        "&:after": {
          backgroundColor: "#eed484",
          borderRadius: "30rem",
          bottom: "0",
          content: '""',
          display: "block",
          height: ".6rem",
          left: "50%",
          position: "absolute",
          WebkitTransform: "translateX(-50%)",
          transform: "translateX(-50%)",
          width: "7rem",
        },
      }}
    >
      {text}
    </Typography>
  );
};

export default TextHeader;
