import "./App.css";
import Header from "./components/layout/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/layout/Footer";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Port from "./pages/divisions/Port";
import { Box } from "@mui/material";
import Beton from "./pages/divisions/Beton";
import Logistic from "./pages/divisions/Logistic";
import CementTerminal from "./pages/divisions/CementTerminal";
import Delivery from "./pages/divisions/Delivery";
import Storage from "./pages/divisions/Storage";
import SpecialEquipments from "./pages/divisions/SpecialEquipment";
import ZBV from "./pages/divisions/ZBV";
import ZD from "./pages/divisions/ZD";
import Blog from "./pages/Blog";
import ViewBlogPage from "./pages/ViewBlogPage";
import AboutCompany from "./pages/AboutCompany";

const App = () => {
  const [language, setLanguage] = useState("uk");

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  useEffect(() => {
    const lg = localStorage.getItem("language");
    if (lg === "en") {
      setLanguage("en");
    } else {
      setLanguage("uk");
    }
  }, []);

  const Layout = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [location.pathname]);

    return (
      <>
        <Header language={language} setLanguage={setLanguage} />
        <Box sx={{ marginTop: location.pathname === "/" ? "0px" : "100px" }}>
          {children}
        </Box>
        <Footer language={language} />
      </>
    );
  };

  return (
    <>
      <Router>
        <Routes>
          <Route
            path={"/"}
            element={<Layout children={<HomePage language={language} />} />}
          />
          <Route
            path={"/port"}
            element={<Layout children={<Port language={language} />} />}
          />{" "}
          <Route
            path={"/beton"}
            element={<Layout children={<Beton language={language} />} />}
          />
          <Route
            path={"/logistic"}
            element={<Layout children={<Logistic language={language} />} />}
          />
          <Route
            path={"/cement-terminal"}
            element={
              <Layout children={<CementTerminal language={language} />} />
            }
          />
          <Route
            path={"/delivery"}
            element={<Layout children={<Delivery language={language} />} />}
          />
          <Route
            path={"/storage"}
            element={<Layout children={<Storage language={language} />} />}
          />{" "}
          <Route
            path={"/special-equipment"}
            element={
              <Layout children={<SpecialEquipments language={language} />} />
            }
          />
          <Route
            path={"/zbv"}
            element={<Layout children={<ZBV language={language} />} />}
          />
          <Route
            path={"/zd"}
            element={<Layout children={<ZD language={language} />} />}
          />
          <Route
            path={"/blog/:id"}
            element={<Layout children={<ViewBlogPage language={language} />} />}
          />
          <Route
            path={"/blog"}
            element={<Layout children={<Blog language={language} />} />}
          />
          <Route
            path={"/about-company"}
            element={<Layout children={<AboutCompany language={language} />} />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
