import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import cargo from "../../assets/carboge.jpg";
import logistic from "../../assets/logistic.jpg";

const Port = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader
        text={isUkrainian ? "Цементний термінал" : "Cement terminal"}
      />
      <Typography
        variant={"h6"}
        sx={{ margin: "10px 0px 50px 0px", textAlign: "center" }}
      >
        {isUkrainian
          ? "Наша компанія з радістю представляє вам наш сучасний цементний термінал, який є ключовою ланкою в постачанні високоякісного цементу для різних будівельних проектів. Ми пишаємося нашим великим досвідом у цій галузі та високим рівнем професіоналізму нашої команди. Ми готові стати вашим надійним постачальником якісного цементу для будь-яких будівельних потреб. Звертайтесь до нас замовленнями та питаннями - ми завжди раді допомогти вам реалізувати ваші проекти з найвищою якістю та ефективністю."
          : "Our company is pleased to present to you our state-of-the-art cement terminal, which is a key link in the supply of high-quality cement for various construction projects. We are proud of our extensive experience in this field and the high level of professionalism of our team. We are ready to become your reliable supplier of quality cement for any construction needs. Contact us with orders and questions - we are always happy to help you implement your projects with the highest quality and efficiency."}
      </Typography>
      <Box
        component={"img"}
        src={cargo}
        alt={"cargo"}
        sx={{ maxWidth: "100%" }}
      />
    </Container>
  );
};

export default Port;
