import React from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import logo from "../../assets/logoRmvBack.png";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { theme } from "../../theme/theme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import port from "../../assets/newPort.jpg";
import carboge from "../../assets/carboge.jpg";
import delivery from "../../assets/lastBackLight.jpg";
import saving from "../../assets/cement.jpg";
import specials from "../../assets/specialTransport.jpg";
import logistic from "../../assets/logistic.jpg";
import ZBP from "../../assets/ZBP.jpg";
import cement from "../../assets/beton.jpg";
import ZD from "../../assets/ZD.jpg";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const structure = [
  {
    name_ukr: "Порт",
    name_en: "Port",
    image: port,
    link: "/port",
    subMenu: [
      {
        name_ukr: "Флот",
        name_en: "Flot",
        image: carboge,
        link: "/port",
      },
      {
        name_ukr: "Цементний термінал",
        name_en: "Cement terminal",
        image: carboge,
        link: "/cement-terminal",
      },
      {
        name_ukr: "Зберігання",
        name_en: "Storage",
        image: saving,
        link: "/storage",
      },
      {
        name_ukr: "Логистика",
        name_en: "Logistics",
        image: logistic,
        link: "/logistic",
      },
    ],
  },
  {
    name_ukr: "Бетон",
    name_en: "Сoncrete",
    image: cement,
    link: "/beton",
    subMenu: [
      {
        name_ukr: "ЗБВ",
        name_en: "RCP",
        image: ZBP,
        link: "/zbv",
      },
    ],
  },
  {
    name_ukr: "Послуги",
    name_en: "Services",
    image: ZBP,
    link: null,
    subMenu: [
      {
        name_ukr: "Доставка",
        name_en: "Delivery",
        image: delivery,
        link: "/delivery",
      },
      {
        name_ukr: "Спеціальна техніка",
        name_en: "Special equipment",
        image: specials,
        link: "/special-equipment",
      },
      {
        name_ukr: "ЗД",
        name_en: "Railway",
        image: ZD,
        link: "/zd",
      },
    ],
  },
  // {
  //   name_ukr: "Наші об'єкти",
  //   name_en: "Our products",
  //   image: ZBP,
  //   link: "/",
  // },
  {
    name_ukr: "Про компанію",
    name_en: "About company",
    image: ZBP,
    link: "/about-company",
  },
  {
    name_ukr: "Блог",
    name_en: "Blog",
    image: ZBP,
    link: "/blog",
  },
];

function NewComponent(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isopen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: 600,
          transitionDuration: "0.8s",
          borderRadius: "10px",
          padding: "10px",
          position: "relative",
          "&::after": {
            content: "''",
            position: "absolute",
            width: "100%",
            transform: "scaleX(0)",
            height: "2px",
            bottom: "0",
            left: "0",
            backgroundColor: "black",
            transformOrigin: "bottom right",
            transition: "transform 0.25s ease-out",
          },
        }}
        onMouseOver={handleClick}
      >
        {props.language === "uk" ? props.item.name_ukr : props.item.name_en}
      </Typography>
      <Menu
        id={`basic-menu-${props.item.name_en}`}
        anchorEl={anchorEl}
        open={isopen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "fade-button",
          onMouseLeave: () => setAnchorEl(null),
          sx: { py: 2, px: 1, maxWidth: "300px", maxHeight: "400px" },
        }}
        M
      >
        {props.item.subMenu.map((subMenu) => {
          return (
            <MenuItem
              onClick={(event) => {
                event.preventDefault();
                navigate(subMenu.link);
                setAnchorEl(null);
              }}
              sx={{
                padding: "10px",
                fontWeight: 600,
                transitionDuration: "0.8s",
                background: "white !important",
                borderRadius: "10px",
                position: "relative",
                color: "black",
                textAlign: "center",
                "&::after": {
                  content: "''",
                  position: "absolute",
                  width: "100%",
                  transform: "scaleX(0)",
                  height: "2px",
                  bottom: "0",
                  left: "0",
                  backgroundColor: "black",
                  transformOrigin: "bottom right",
                  transition: "transform 0.25s ease-out",
                },
                "&:hover::after": {
                  transform: "scaleX(1)",
                  transformOrigin: "bottom left",
                },
                "&:hover": {
                  background: "white",
                },
              }}
            >
              {props.language === "uk" ? subMenu.name_ukr : subMenu.name_en}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

const Header = ({ language, setLanguage }) => {
  const navigate = useNavigate();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  // const [headerOpacity, setHeaderOpacity] = useState(0.4);
  // const [_, setLastScroll] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     setLastScroll((lastScroll) => {
  //       if (lastScroll > scrollY) {
  //         setHeaderOpacity((prev) => prev - (lastScroll - scrollY) / 300);
  //       } else {
  //         setHeaderOpacity((prev) => prev + (scrollY - lastScroll) / 300);
  //       }
  //
  //       return scrollY;
  //     });
  //   };
  //   document.addEventListener("scroll", handleScroll);
  //   return () => {
  //     document.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <AppBar
      position={"fixed"}
      sx={{
        height: "100px",
        backgroundColor: `rgb(255, 255, 255, 1)`,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        px: { xs: "5px", md: "40px" },
        columnGap: "50px",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "20px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <img src={logo} height={"100px"} alt={"logo"} />
      </Box>

      {mdUp ? (
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "50px" }}>
          <Box sx={{ display: "flex", columnGap: "25px" }}>
            {structure.map((item) => {
              return (
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  to={item.link}
                >
                  {item.subMenu ? (
                    <NewComponent
                      onClick={item.link ? () => navigate(item.link) : () => {}}
                      language={language}
                      item={item}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontWeight: 600,
                        transitionDuration: "0.8s",
                        borderRadius: "10px",
                        padding: "10px",
                        position: "relative",
                        "&::after": {
                          content: "''",
                          position: "absolute",
                          width: "100%",
                          transform: "scaleX(0)",
                          height: "2px",
                          bottom: "0",
                          left: "0",
                          backgroundColor: "black",
                          transformOrigin: "bottom right",
                          transition: "transform 0.25s ease-out",
                        },
                      }}
                    >
                      {language === "uk" ? item.name_ukr : item.name_en}
                    </Typography>
                  )}
                </Link>
              );
            })}
          </Box>

          <Box sx={{ display: "flex", columnGap: "20px" }}>
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              sx={{
                color: "black",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  sx: {
                    backgroundColor: "white",
                    "& .MuiMenuItem-root": {
                      padding: 2,
                      color: "black",
                    },
                    "& .MuiList-root": {
                      padding: 0,
                    },
                  },
                },
              }}
            >
              <MenuItem value={"uk"} sx={{ color: "black" }}>
                Укр
              </MenuItem>
              <MenuItem value={"en"} sx={{ background: "transparent" }}>
                Eng
              </MenuItem>
            </Select>
          </Box>
        </Box>
      ) : (
        <>
          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              ...(open && { display: "none" }),
              marginRight: "30px",
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            sx={{
              width: 240,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: 240,
              },
              ...(!open && { display: "none" }),
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <List>
              {structure.map((item) => (
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  to={item.link}
                  onClick={() => setOpen(false)}
                >
                  <ListItem key={item.link} disablePadding>
                    <ListItemButton>
                      <ListItemText
                        sx={{ color: "black" }}
                        primary={
                          language === "uk" ? item.name_ukr : item.name_en
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
            <Divider />
            <Box sx={{ display: "flex", columnGap: "20px" }}>
              <List
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                  justifyContent: "center",
                }}
              >
                <ListItem
                  onClick={() => setLanguage("uk")}
                  key={"Укр"}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{
                        color: "black",
                        fontWeight:
                          language === "uk" ? "700px !important" : 500,
                      }}
                    >
                      Укр
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => setLanguage("en")}
                  key=" Eng"
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{
                        color: "black",
                        fontWeight: language === "en" ? "700 !important" : 500,
                      }}
                      primary={" Eng"}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </>
      )}
    </AppBar>
  );
};

export default Header;
