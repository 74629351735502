import React from "react";
import { blogs } from "../materials/blog";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import TextHeader from "../components/TextHeader";

const Blog = ({ language }) => {
  const navigate = useNavigate();
  const isUkrainian = language === "uk";
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <TextHeader text={isUkrainian ? "Блог" : "Blog"} />
      <Container
        maxWidth={"lg"}
        sx={{
          position: "relative",
          margin: "0px 0px 50px 0px",
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          columnGap: "30px",
          rowGap: "30px",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
        }}
      >
        {/*{(showMore ? structure : structure.slice(0, 6)).map((item, key) => {*/}
        {blogs.map((item, key) => {
          return (
            <Card
              key={key}
              sx={{
                "&:hover": {
                  "& > .MuiCardMedia-root": {
                    opacity: 0.8,
                  },
                },
              }}
            >
              <CardMedia
                sx={{
                  height: 200,
                  opacity: "1",
                  transitionDuration: "0.8s",
                }}
                image={item.image}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ color: "black", textAlign: "center" }}
                >
                  {isUkrainian ? item.name_ukr : item.name_en}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    whiteSpace: "pre-line",
                    textAlign: "center",
                  }}
                >
                  {isUkrainian ? item.description_uk : item.description_en}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  // variant={"outlined"}
                  color={"primary"}
                  sx={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    transitionDuration: "0.8s",
                    "&:hover": {
                      background: "black",
                      color: "white",
                      "& > svg": {
                        fill: "white",
                      },
                    },
                  }}
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => navigate(`/blog/${key + 1}`)}
                >
                  Читати
                </Button>
              </CardActions>
            </Card>
          );
        })}
      </Container>
    </Box>
  );
};

export default Blog;
