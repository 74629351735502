import React from "react";
import { Box } from "@mui/material";
import { galleryImages } from "../assets/gallery/galleryImages";
import TextHeader from "../components/TextHeader";

const Port = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Box sx={{ marginTop: "130px" }}>
      <TextHeader text={isUkrainian ? "Про компанію" : "About company"} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          justifyContent: "center",
          rowGap: "20px",
          padding: "20px",
          columnGap: "20px",
        }}
      >
        {galleryImages.map((item, key) => {
          return (
            <Box
              sx={{ display: "flex", justifyContent: "center", rowGap: "20px" }}
            >
              <Box
                key={key}
                component={"img"}
                src={item}
                alt={"img" + key}
                sx={{ width: { xs: "100%", md: "45%" } }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Port;
