import React from "react";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import insta from "../../assets/icons/instagramIcon.svg";
import facebookIcon from "../../assets/icons/facebookIcon.svg";
import youtubeIcon from "../../assets/icons/youtube.svg";
import logo from "../../assets/logoRmvBack.png";

const Footer = ({ language }) => {
  const isUkrainian = language === "uk";
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: lgDown ? "24px 16px" : "56px 88px 68px 80px",
        // backgroundColor: "rgba(234, 239, 251, 1)",
        background: "transparent",
      }}
    >
      <img src={logo} alt={""} style={{ width: "200px" }} />
      <Divider
        sx={{
          backgroundColor: "rgba(119, 137, 180, 1)",
          opacity: "0.2",
          width: "80%",
          margin: "40px",
        }}
      />
      <Box
        sx={
          lgDown
            ? {
                width: "90%",
                display: "grid",
                justifyContent: "center",
                rowGap: "40px",
                gridTemplateAreas: "'info' 'contacts' 'socials'",
              }
            : {
                width: "90%",
                display: "grid",
                justifyContent: "center",
                columnGap: "100px",
                gridTemplateAreas: "'socials contacts' 'socials info'",
              }
        }
      >
        <Box
          sx={{
            gridArea: "socials",
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
            alignItems: lgDown ? "center" : "flex-start",
            width: lgDown ? "100%" : "350px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "black",
            }}
          >
            {isUkrainian
              ? "Cтежте за нами в соц. мережах"
              : "Follow us on social networks"}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              width: "100%",
              ...(lgDown && { textAlign: "center" }),
            }}
          >
            {isUkrainian
              ? " Підписуйтесь на наші соціальні мережі, щоб бути в курсі всіх наших останніх новин!"
              : "Follow our social networks to stay up to date with all of our latest news!"}
          </Typography>
          <Box
            sx={{ display: "flex", columnGap: "20px", alignItems: "center" }}
          >
            <Link
              href={"https://www.instagram.com/ank_fleet"}
              target={"_blank"}
            >
              <img src={insta} alt={""} />
            </Link>
            <Link
              href={"https://www.facebook.com/ANKvodniTransport"}
              target={"_blank"}
            >
              <img src={facebookIcon} alt={""} />
            </Link>
            <Link
              href={"https://www.youtube.com/@ANKCompany"}
              target={"_blank"}
            >
              <img src={youtubeIcon} alt={""} />
            </Link>
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: "500",
            }}
          >{`Сopyright ${new Date().getFullYear()}`}</Typography>
        </Box>
        <Box sx={{ gridArea: "info", textAlign: lgDown ? "center" : "left" }}>
          <Typography sx={{ paddingLeft: "16px" }}>
            {isUkrainian ? "Адреса" : "Address"}
          </Typography>
          <List sx={{ "& > li": { fontWeight: 500 } }}>
            <ListItem sx={lgDown ? { justifyContent: "center" } : {}}>
              {isUkrainian
                ? "Украина, м. Херсон, Острівське шосе, 10а"
                : "Ukraine, Kherson, Ostrivske Shosse, 10a"}
            </ListItem>
          </List>
        </Box>
        <Box
          sx={{ gridArea: "contacts", textAlign: lgDown ? "center" : "left" }}
        >
          <Typography sx={{ paddingLeft: "16px" }}>
            {isUkrainian ? "Контакти" : "Contacts"}
          </Typography>
          <List sx={{ "& > li": { fontWeight: 500 } }}>
            <ListItem sx={lgDown ? { justifyContent: "center" } : {}}>
              тел. +38 (050) 318 77 99
            </ListItem>
            {/*<ListItem sx={lgDown ? { justifyContent: "center" } : {}}>*/}
            {/*  puzzle@gmail.com*/}
            {/*</ListItem>*/}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
