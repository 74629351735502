import birthday from "../assets/blog/birthday.jpg";

export const blogs = [
  {
    name_ukr: "День народження компанії!",
    name_en: "Company's birthday!",
    image: birthday,
    description_uk:
      "29 років компанії АНК!\n" +
      "Продовжуємо працювати на перемогу України! \n" +
      "Будуємо, підтримуємо, працюємо! \n" +
      "Наш досвід - наша гордість!\n" +
      "Рухаємось вперед!",
    description_en:
      "29 years of the ANC company!\n" +
      "We continue to work for the victory of Ukraine! \n" +
      "We build, support, work! \n" +
      "Our experience is our pride!\n" +
      "Let's move forward!",
  },
];
