import React, { useRef } from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import specials from "../../assets/specialTransport.jpg";

const SpecialEquipments = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader
        text={isUkrainian ? "Спеціальне обладнання" : "Special Equipment"}
      />
      <Typography
        variant={"h6"}
        sx={{ margin: "10px 0px 50px 0px", textAlign: "center" }}
      >
        {isUkrainian
          ? 'Компанія "ANK" налічує понад 100 одиниць спеціальної техніки, яка працює безперервно:\n' +
            "самоскиди, \n" +
            "міксери, \n" +
            "бетононасоси, \n" +
            "навантажувачі, \n" +
            "екскаватори, \n" +
            "маніпулятори, \n" +
            "цементовози, \n" +
            "перевантажувачі,\n" +
            "гідромолоти тощо.\n"
          : 'The "ANK" company has more than 100 units of special equipment that works continuously:\n ' +
            "dump trucks, \n" +
            "mixers, \n" +
            "concrete pumps\n" +
            "loaders, \n" +
            "excavators, \n" +
            "manipulators, \n" +
            "cement trucks, \n" +
            "reloaders,\n" +
            "hydraulic hammers, etc."}
      </Typography>
      <Box
        component={"img"}
        src={specials}
        alt={"logistic"}
        sx={{ maxWidth: "100%" }}
      />
    </Container>
  );
};

export default SpecialEquipments;
