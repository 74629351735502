import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import backImage from "../assets/newBack.jpg";
import logo from "../assets/logoBlackRmv.png";
import bgGradient from "../assets/bg-gradient.jpg";
import delivery from "../assets/delivery.jpg";
import flot from "../assets/newPort.jpg";
import carboge from "../assets/carboge.jpg";
import port from "../assets/port.jpg";
import cement from "../assets/beton.jpg";
import specials from "../assets/specialTransport.jpg";
import lab from "../assets/lab.jpg";
import saving from "../assets/cement.jpg";
import logistic from "../assets/logistic.jpg";
import ZBP from "../assets/ZBP.jpg";
import ZD from "../assets/railway.jpg";
import mobileBack from "../assets/mobileBack.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import { blogs } from "../materials/blog";

export const structure = [
  {
    name_ukr: "Порт",
    name_en: "Port",
    image: port,
    link: "/port",
  },
  {
    name_ukr: "Цементний термінал",
    name_en: "Cement terminal",
    image: carboge,
    link: "/cement-terminal",
  },
  {
    name_ukr: "Доставка",
    name_en: "Delivery",
    image: delivery,
    link: "/delivery",
  },
  {
    name_ukr: "Зберігання",
    name_en: "Storage",
    image: saving,
    link: "/storage",
  },
  {
    name_ukr: "Спеціальна техніка",
    name_en: "Special equipment",
    image: specials,
    link: "/special-equipment",
  },
  {
    name_ukr: "Логистика",
    name_en: "Logistics",
    image: logistic,
    link: "/logistic",
  },
  {
    name_ukr: "ЗБВ",
    name_en: "RCP",
    image: ZBP,
    link: "/zbv",
  },
  {
    name_ukr: "Бетон",
    name_en: "Сoncrete",
    image: cement,
    link: "/beton",
  },
  {
    name_ukr: "ЗД",
    name_en: "Railway",
    image: ZD,
    link: "/zd",
  },
];

const HomePage = ({ language }) => {
  const navigate = useNavigate();
  const isUkrainian = language === "uk";
  // const [showMore, isShowMore] = useState(false);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          minHeight: { xs: "50px", md: "100vh" },
          width: { xs: "100vw", md: "100%" },
          aspectRatio: { xs: "1", md: "unset" },
          backgroundImage: {
            xs: `url(${mobileBack})`,
            md: `url(${backImage})`,
          },
          backgroundSize: "cover",
          display: { xs: "flex", md: "block" },
          flexDirection: "column",
          rowGap: "10px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "100px 50px",
        }}
      >
        <Typography
          align={"center"}
          variant={"h4"}
          sx={{
            fontWeight: "600",
            margin: "0px 0px 50px 0px",
            position: "relative",
            paddingBottom: "2rem",
            "&:after": {
              backgroundColor: "#eed484",
              borderRadius: "30rem",
              bottom: "0",
              content: '""',
              display: "block",
              height: ".6rem",
              left: "50%",
              position: "absolute",
              WebkitTransform: "translateX(-50%)",
              transform: "translateX(-50%)",
              width: "7rem",
            },
          }}
        >
          {isUkrainian ? "Про нас" : "About us"}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
            columnGap: "40px",
            rowGap: "50px",
            padding: "20px 0px",
            marginTop: "40px",
          }}
        >
          {structure.map((item, key) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "10px",
                }}
              >
                <Box
                  key={key}
                  sx={{
                    width: "400px",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    borderRadius: "20px",
                    "&:hover": {
                      "& > img": {
                        opacity: "0.7 !important",
                      },
                    },
                  }}
                  onClick={() => navigate(item.link)}
                >
                  <img
                    src={item.image}
                    width={"100%"}
                    height={"100%"}
                    style={{
                      opacity: "1",
                      position: "absolute",
                      zIndex: -1,
                      transitionDuration: "0.8s",
                      borderRadius: "20px",
                    }}
                    alt={"background"}
                  />
                </Box>
                <Typography
                  variant={"h5"}
                  sx={{ fontWeight: 600, color: "black" }}
                >
                  {isUkrainian ? item.name_ukr : item.name_en}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "1.2rem !important",
            maxWidth: "1200px",
            textAlign: "center",
            margin: "50px 0px 50px 0px",
          }}
        >
          {isUkrainian
            ? "Група компаній ANK працює на ринку будівельних послуг понад 20 років. Основний діяльность компанії направлена на забезпечення великих і малих будівельних об'єктів, домогосподарств, садівнитств по Херсону та Херсонській області матеріалами високої якості: бетон, щебінь , пісок, цемент, відсів, розчини та безліч залізобетонних виробів. Власна лабораторія гарантує високу якість готової продукції та швидкість виготовлення."
            : "The ANK group of companies has been working on the construction services market for more than 20 years. The main activity of the company is aimed at providing large and small construction sites, households, horticulture in Kherson and the Kherson region with high-quality materials: concrete, crushed stone, sand, cement, sifting, solutions and many reinforced concrete products. Our own laboratory guarantees the high quality of finished products and the speed of production."}
        </Typography>
        <Link
          to={
            isUkrainian
              ? "/presentation/ANK_uk.pdf"
              : "/presentation/ANK_eng.pdf"
          }
          target="_blank"
          download={"ANK_presentation"}
          color={"primary"}
        >
          <Button
            variant={"outlined"}
            color={"primary"}
            size={"large"}
            sx={{
              background: "black",
              color: "white",
              "& > svg": {
                fill: "white",
              },
              borderRadius: "10px",
              padding: "15px 24px",
              transitionDuration: "0.8s",
              "&:hover": {
                color: "black",
                "& > svg": {
                  fill: "black",
                },
              },
            }}
            endIcon={<ArrowCircleDownIcon />}
          >
            {isUkrainian
              ? "Завантажити презентацію"
              : "Download the presentation"}
          </Button>
        </Link>
      </Box>

      <Box
        sx={{
          position: "relative",
          height: "max-content",
          backgroundImage: `url(${bgGradient})`,
          backgroundSize: "cover",
          padding: "10px 40px",
        }}
      >
        <Typography
          align={"center"}
          variant={"h4"}
          sx={{
            fontWeight: "600",
            margin: "40px 0px 10px 0px",
            position: "relative",
            paddingBottom: "2rem",
            "&:after": {
              backgroundColor: "#eed484",
              borderRadius: "30rem",
              bottom: "0",
              content: '""',
              display: "block",
              height: ".6rem",
              left: "50%",
              position: "absolute",
              WebkitTransform: "translateX(-50%)",
              transform: "translateX(-50%)",
              width: "7rem",
            },
          }}
        >
          {isUkrainian ? "Чому ми" : "Why us"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            component={"img"}
            src={flot}
            alt={"logo"}
            sx={{ width: { xs: "100%", md: "45%" } }}
          />
          <Box
            sx={{
              padding: { xs: "10px", md: "60px" },
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
              fontSize: "16px",
              "& > p": {
                fontSize: "18px !important",
              },
            }}
          >
            <Typography>
              {isUkrainian
                ? "За десятки років ми розробили найбільш оптимальні логистичні схеми поставок, завдяки яким гарантуємо своєчасну доставку матеріалів. Контролюємо маршрут і доставку кожного замовлення, адже добре знаємо надійне будівництво – це не тільки якісні матеріали, але й чесні, професійні відносини. Нам дуже важливо, аби з нами Ви відчували впевненість та надійність."
                : "For decades, we have developed the most optimal logistics supply schemes, thanks to which we guarantee the timely delivery of materials. We control the route and delivery of each order, because we know well that reliable construction is not only high-quality materials, but also honest, professional relations. It is very important to us that you feel confident and reliable with us."}
            </Typography>
            <Typography>
              {isUkrainian
                ? "У кожному аспекті нашої діяльності ми орієнтуємося на інновації та сталий розвиток, прагнемо будувати світ із турботою про людей та планету. Наші переваги:"
                : "In every aspect of our activity, we focus on innovation and sustainable development, we strive to build a world with care for people and the planet. Our advantages:"}
            </Typography>
            <Box
              component={"ul"}
              sx={{
                "& > li": {
                  margin: "10px 0px",
                },
              }}
            >
              <li>
                {isUkrainian ? (
                  <>
                    <strong>Широкий асортимент:</strong> у нас ви знайдете все
                    необхідне - від будівельних матеріалів до інструментів та
                    обладнання.{" "}
                  </>
                ) : (
                  <>
                    <strong>Wide range:</strong> you will find everything here
                    necessary - from building materials to tools and equipment.{" "}
                  </>
                )}
              </li>
              <li>
                {isUkrainian ? (
                  <>
                    <strong>Висока якість:</strong> ми працюємо лише з надійними
                    виробниками, які гарантують якість своєї продукції.
                  </>
                ) : (
                  <>
                    <strong>High quality:</strong> we only work with reliable
                    ones manufacturers who guarantee the quality of their
                    products.
                  </>
                )}
              </li>
              <li>
                {isUkrainian ? (
                  <>
                    <strong>Індивідуальний підхід:</strong> ми розуміємо, що
                    кожен проект унікальний, тому готові підлаштуватись до вашої
                    специфіки та потреб.
                  </>
                ) : (
                  <>
                    <strong>Individual approach:</strong> we understand that
                    everyone the project is unique, so we are ready to adapt to
                    your specifics and needs
                  </>
                )}
              </li>
              <li>
                {isUkrainian ? (
                  <>
                    <strong>Компетентні фахівці:</strong> наша команда
                    складається з досвідчених фахівців, які завжди готові надати
                    вам професійну консультацію.
                  </>
                ) : (
                  <>
                    <strong>Competent specialists:</strong> our team is formed
                    from experienced specialists who are always ready to provide
                    you with a professional consultation
                  </>
                )}
              </li>
              <li>
                {isUkrainian ? (
                  <>
                    <strong>Чесні ціни:</strong> ми пропонуємо конкурентні ціни
                    нашим клієнтам, завжди дбаючи про ваш бюджет.
                  </>
                ) : (
                  <>
                    <strong>Fair prices:</strong> we offer competitive prices to
                    our customers, always keeping your budget in mind.
                  </>
                )}
              </li>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography
          align={"center"}
          variant={"h4"}
          sx={{
            fontWeight: "600",
            margin: "100px 0px 50px 0px",
            position: "relative",
            paddingBottom: "2rem",
            "&:after": {
              backgroundColor: "#eed484",
              borderRadius: "30rem",
              bottom: "0",
              content: '""',
              display: "block",
              height: ".6rem",
              left: "50%",
              position: "absolute",
              WebkitTransform: "translateX(-50%)",
              transform: "translateX(-50%)",
              width: "7rem",
            },
          }}
        >
          {isUkrainian ? "Наша лабораторія" : "Our laboratory"}
        </Typography>
        <Box
          sx={{
            position: "relative",
            margin: "0px 0px 50px 0px",
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
            alignItems: "center",
            justifyContent: "center",
            columnGap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "20px !important",
                padding: "10px 30px",
              }}
            >
              Ми - команда експертів у сфері бетонних технологій, присвячених
              забезпеченню високої якості та надійності бетонних рішень. Наша
              лабораторія обладнана останнім словом техніки, що дозволяє
              проводити ретельний аналіз складу бетону та перевірку на
              відповідність нормам і стандартам.
            </Typography>
            <Typography
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "20px !important",
                padding: "10px 30px",
              }}
            >
              Незалежно від масштабу вашої будівництва, наші висококваліфіковані
              фахівці допоможуть вам досягти оптимальних результатів і
              забезпечити довговічність вашої споруди. Покладаючись на наші
              досвід і надійність, ви можете бути впевнені, що ваші будівельні
              проекти будуть зведені на міцному та стійкому бетонному
              фундаменті.
            </Typography>
            <Typography
              sx={{
                color: "black",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "17px !important",
                padding: "20px 30px",
                maxWidth: { xs: "95%", md: "60%" },
              }}
            >
              Зв'яжіться з нами сьогодні, щоб дізнатись більше про наші послуги
              та переваги співпраці з нами.
            </Typography>
          </Box>

          <Box
            component={"img"}
            src={lab}
            alt={"logo"}
            sx={{ width: { xs: "100%", md: "90%" }, maxHeight: "600px" }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          padding: "10px 0px",
          backgroundImage: `url(${bgGradient})`,
        }}
      >
        <Typography
          align={"center"}
          variant={"h4"}
          sx={{
            fontWeight: "600",
            margin: "100px 0px 50px 0px",
            position: "relative",
            paddingBottom: "2rem",
            "&:after": {
              backgroundColor: "#eed484",
              borderRadius: "30rem",
              bottom: "0",
              content: '""',
              display: "block",
              height: ".6rem",
              left: "50%",
              position: "absolute",
              WebkitTransform: "translateX(-50%)",
              transform: "translateX(-50%)",
              width: "7rem",
            },
          }}
        >
          {isUkrainian ? "Наш флот" : "АNK-FLEET"}
        </Typography>
        <Box
          sx={{
            position: "relative",
            margin: "0px 0px 50px 0px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            columnGap: "20px",
          }}
        >
          <Box
            sx={{
              padding: "60px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "20px !important",
              }}
            >
              {isUkrainian
                ? "Судноплавною компанією ANK за 2021 рік було перевезено"
                : "It was transported by the shipping company ANK for 2021"}
            </Typography>
            <Typography
              sx={{
                margin: "40px auto",
                fontFamily: "'Ubuntu', sans-serif",
                fontSize: "30px !important",
                fontWeight: "bold",
                color: "black",
                textAlign: "center",
                letterSpacing: "5px",
              }}
            >
              {isUkrainian ? "𝟑𝟖𝟓 𝟎𝟎𝟏 тонн" : "𝟑𝟖𝟓 𝟎𝟎𝟏 tons"}
            </Typography>
            <Typography
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "20px !important",
              }}
            >
              {isUkrainian ? "вантажу, що становить" : "cargo, which is"}
            </Typography>
            <Typography
              sx={{
                margin: "40px auto",
                fontFamily: "'Ubuntu', sans-serif",
                fontSize: "30px !important",
                fontWeight: "bold",
                color: "black",
                textAlign: "center",
                letterSpacing: "5px",
              }}
            >
              𝟐,𝟔𝟖%
            </Typography>
            <Typography
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "20px !important",
              }}
            >
              {isUkrainian
                ? "від загального вантажообігу України."
                : "from the general freight turnover of Ukraine."}
            </Typography>
          </Box>
          <Box
            component={"img"}
            src={delivery}
            alt={"logo"}
            sx={{ width: { xs: "100%", md: "45%" } }}
          />
        </Box>
      </Box>

      {/*<Box*/}
      {/*  sx={{*/}
      {/*    textAlign: "center",*/}
      {/*    display: "flex",*/}
      {/*    flexDirection: "column",*/}
      {/*    alignItems: "center",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Typography*/}
      {/*    align={"center"}*/}
      {/*    variant={"h4"}*/}
      {/*    sx={{*/}
      {/*      fontWeight: "600",*/}
      {/*      margin: "100px 0px 50px 0px",*/}
      {/*      position: "relative",*/}
      {/*      paddingBottom: "2rem",*/}
      {/*      "&:after": {*/}
      {/*        backgroundColor: "#eed484",*/}
      {/*        borderRadius: "30rem",*/}
      {/*        bottom: "0",*/}
      {/*        content: '""',*/}
      {/*        display: "block",*/}
      {/*        height: ".6rem",*/}
      {/*        left: "50%",*/}
      {/*        position: "absolute",*/}
      {/*        WebkitTransform: "translateX(-50%)",*/}
      {/*        transform: "translateX(-50%)",*/}
      {/*        width: "7rem",*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {isUkrainian ? "Новини" : "News"}*/}
      {/*  </Typography>*/}
      {/*  <Container*/}
      {/*    maxWidth={"lg"}*/}
      {/*    sx={{*/}
      {/*      position: "relative",*/}
      {/*      margin: "0px 0px 50px 0px",*/}
      {/*      display: "grid",*/}
      {/*      alignItems: "center",*/}
      {/*      justifyContent: "center",*/}
      {/*      columnGap: "30px",*/}
      {/*      rowGap: "30px",*/}
      {/*      gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    /!*{(showMore ? structure : structure.slice(0, 6)).map((item, key) => {*!/*/}
      {/*    {blogs.map((item, key) => {*/}
      {/*      return (*/}
      {/*        <Card*/}
      {/*          key={key}*/}
      {/*          sx={{*/}
      {/*            "&:hover": {*/}
      {/*              "& > .MuiCardMedia-root": {*/}
      {/*                opacity: 0.8,*/}
      {/*              },*/}
      {/*            },*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <CardMedia*/}
      {/*            sx={{*/}
      {/*              height: 200,*/}
      {/*              opacity: "1",*/}
      {/*              transitionDuration: "0.8s",*/}
      {/*            }}*/}
      {/*            image={item.image}*/}
      {/*          />*/}
      {/*          <CardContent>*/}
      {/*            <Typography variant="h6" sx={{ color: "black" }}>*/}
      {/*              {isUkrainian ? item.name_ukr : item.name_en}*/}
      {/*            </Typography>*/}
      {/*            <Typography sx={{ color: "black", whiteSpace: "pre-line" }}>*/}
      {/*              {isUkrainian ? item.description : item.name_en}*/}
      {/*            </Typography>*/}
      {/*          </CardContent>*/}
      {/*          <CardActions sx={{ justifyContent: "center" }}>*/}
      {/*            <Button*/}
      {/*              // variant={"outlined"}*/}
      {/*              color={"primary"}*/}
      {/*              sx={{*/}
      {/*                borderRadius: "10px",*/}
      {/*                padding: "10px 20px",*/}
      {/*                transitionDuration: "0.8s",*/}
      {/*                "&:hover": {*/}
      {/*                  background: "black",*/}
      {/*                  color: "white",*/}
      {/*                  "& > svg": {*/}
      {/*                    fill: "white",*/}
      {/*                  },*/}
      {/*                },*/}
      {/*              }}*/}
      {/*              endIcon={<ArrowForwardIcon />}*/}
      {/*              onClick={() => navigate(item.link)}*/}
      {/*            >*/}
      {/*              Читати*/}
      {/*            </Button>*/}
      {/*          </CardActions>*/}
      {/*        </Card>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </Container>*/}
      {/*  /!*{!showMore && (*!/*/}
      {/*  /!*  <Button*!/*/}
      {/*  /!*    variant={"outlined"}*!/*/}
      {/*  /!*    color={"primary"}*!/*/}
      {/*  /!*    onClick={() => isShowMore(true)}*!/*/}
      {/*  /!*    sx={{*!/*/}
      {/*  /!*      borderRadius: "10px",*!/*/}
      {/*  /!*      padding: "10px 16px",*!/*/}
      {/*  /!*      transitionDuration: "0.8s",*!/*/}
      {/*  /!*      "&:hover": {*!/*/}
      {/*  /!*        background: "black",*!/*/}
      {/*  /!*        color: "white",*!/*/}
      {/*  /!*        "& > svg": {*!/*/}
      {/*  /!*          fill: "white",*!/*/}
      {/*  /!*        },*!/*/}
      {/*  /!*      },*!/*/}
      {/*  /!*    }}*!/*/}
      {/*  /!*    endIcon={<ArrowCircleDownIcon />}*!/*/}
      {/*  /!*  >*!/*/}
      {/*  /!*    {isUkrainian ? "Показати більше" : "Show more"}*!/*/}
      {/*  /!*  </Button>*!/*/}
      {/*  /!*)}*!/*/}
      {/*</Box>*/}
    </>
  );
};

export default HomePage;
