import React, { useRef } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import TextHeader from "../../components/TextHeader";

const Port = ({ language }) => {
  const isUkrainian = language === "uk";
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const iframe = useRef();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader text={isUkrainian ? "Порт" : "Port"} />
      <Typography
        variant={"h6"}
        sx={{
          margin: { xs: "5px", md: "10px 50px 50px 50px" },
          textAlign: "center",
        }}
      >
        {isUkrainian
          ? "Наша компанія пропонує надійні та ефективні послуги доставки для вашого зручного та безперебійного досвіду. Ми розуміємо, наскільки важлива є своєчасна та професійна доставка, тому гарантуємо, що ваші товари та вантажі будуть доставлені вчасно та безпечно. Судноплавною компанією ANK за 2021 рік було перевезено 𝟑𝟖𝟓 𝟎𝟎𝟏 тонн вантажу, що становить 𝟐,𝟔𝟖% від загального вантажообігу України."
          : "Our company offers reliable and efficient delivery services for your convenient and hassle-free experience. We understand how important timely and professional delivery is, so we guarantee that your goods and cargo will be delivered on time and safely. ANK shipping company transported 𝟑𝟖𝟓 𝟎𝟎𝟏 tons of cargo in 2021, which is 𝟐.𝟔𝟖% of the total cargo turnover of Ukraine."}
      </Typography>
      <iframe
        style={{ width: "80%", height: mdUp ? "500px" : "300px" }}
        ref={iframe}
        src={`https://www.youtube.com/embed/zlkz10gCkaQ`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Box>
  );
};

export default Port;
