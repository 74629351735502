import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import cargo from "../../assets/cement.jpg";

const Storage = ({ language }) => {
  const isUkrainian = language === "uk";
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextHeader text={isUkrainian ? "Зберігання" : "Storage"} />
      <Typography
        variant={"h6"}
        sx={{ margin: "10px 0px 50px 0px", textAlign: "center" }}
      >
        {isUkrainian
          ? "Наша компанія надає надійні та безпечні послуги зберігання для вашого товару або вантажу. Ми розуміємо, що ефективне зберігання є важливим елементом успішного бізнесу, тому забезпечуємо нашим клієнтам оптимальні умови для зберігання їхнього майна. Ми готові стати вашим надійним партнером у зберіганні товарів та вантажів. Звертайтесь до нас для отримання детальної інформації про послуги зберігання та отримайте високоякісне та професійне обслуговування, яке допоможе оптимізувати ваш бізнес та забезпечить надійний захист вашого майна."
          : "Our company provides reliable and safe storage services for your goods or cargo. We understand that effective storage is an important element of a successful business, so we provide our customers with optimal conditions for storing their property. We are ready to become your reliable partner in the storage of goods and cargo. Contact us for detailed information on storage services and receive high-quality and professional service that will help optimize your business and ensure reliable protection of your property."}
      </Typography>
      <Box
        component={"img"}
        src={cargo}
        alt={"cargo"}
        sx={{ maxWidth: "100%" }}
      />
    </Container>
  );
};

export default Storage;
